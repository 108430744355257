import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Smart Contracts",
        tab2 = "Firmware",
        tab3 = "Research"
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <p>
                                                I am a smart contract engineer with experience developing Ethereum and Tezos smart contracts. 
                                                I have contributed to a variety of decentralized applications including a digital identity system for 
                                                the Tezos blockchain and a liquidation bot for the Compound Protocol.
                                                </p>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                        <p>
                                            I also have industry experience in firmware engineering for Intel Corporation. 
                                            In this position I worked with a large team of engineers to develop and validate firmware for solid state drives. 
                                            I gained experience in engineering project management methodologies including agile and scrum.
                                        </p>
                                       </div>
                                    </TabPanel>
                                    <TabPanel>
                                       <div className="single-tab-content">
                                        <p>
                                            While studying at the University of Nevada, Reno, I worked as a researcher in the <a href="https://ecsl.cse.unr.edu/" target="_blank">
                                            UNR Evolutionary Computing Systems Laboratory</a>. During this time I developed several software projects using Unity including
                                            an educational game teaching the core concepts of cybersecurity to children, and a training program to prepare UAV operators for
                                            control of magnetic robots for bridge inspection.
                                        </p>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;