import React from "react";

const BlogContent = [
    {
        images: 'tuvs',
        title: 'Tezos User Verification System',
        category: 'DApp',
        description:
            <div>
                <p>
                    The <span style={{fontWeight: "bold"}}>Tezos User Verification System</span> is a decentralized application I developed using Ligo and deployed to the Hangzhou testnet.
                    This app allows users to mint an NFT to represent their digital identity. 
                    Any decentralized application can then request the user’s permission to access and write information to their digital identity as metadata for the NFT. 
                    The user alone can grant permission to modify their personal information. 
                    In this way, decentralized applications can easily track user information while maintaining data privacy for the user.
                </p>
                <p>
                    I also developed a corresponding web-based game called <span style={{fontWeight: "bold"}}>TezSnake</span> with ReactJs. The game uses the Tezos User Verification System to track usernames, scores,
                    and character personalization.
                </p>
                <p>
                    Check out the Tezos User Verification System and TezSnake <a href="https://master.df331jo17t3b2.amplifyapp.com/" target="_blank">here</a>.
                </p>
            </div>
    },
    {
        images: 'compound',
        title: 'Compound.finance Liquidation Bot',
        category: 'DApp',
        description:
            <div>
                <p>
                    I developed a <span style={{fontWeight: "bold"}}>Liquidation Bot</span> for the Compound Protocol along with a small team of smart
                    contract developers. The bot harnesses AAVE Protocol Flash Loan functionality to seize the collateral funds of accounts eligible
                    for liquidation.
                </p>
                <p>
                    The key feature of the bot is the complex search algorithm we developed to predict account liquidation eligibility. This algorithm
                    allows our bot to be highly competetive and to often be the first to find and exploit liquidatable accounts.
                </p>
            </div>
    },
    {
        images: 'reno',
        title: 'Reno Decentralized Autonomous Organization',
        category: 'DApp',
        description:
            <div>
                <p>
                    The <span style={{fontWeight: "bold"}}>Reno DAO</span> is a project currently under development in collaboration with Reno Mayor Hillary
                    Scheive. The project aims to build a city revenue sharing system for the residents of Reno, NV. The Reno DAO will make use of a digital
                    identity system to distribute a new Reno Coin to those who associate a valid Reno address with their blockchain address. Holders of the
                    Reno Coin will also be able to sell their stake in the DAO, in turn allowing individuals from outside the Reno area to invest in the Reno
                    economy by purchasing Reno Coin.
                </p>
                <p>
                    The short term plans for the DAO are to build an NFT marketplace where local art installations will be available for purchase in the form
                    of NFTs. The first item to be available for sale is the Space Whale statue located in front of Reno City Hall. A portion of artwork sold on
                    the Reno DAO NFT marketplace will be given to the original artist, and all other proceeds will be distrubuted amongst members of the DAO.
                    In this way, local artists will be supported through NFT sales, and individuals will be able to invest in Reno's local art community.
                </p>
                <p>
                    The Reno DAO covered by Wired Magazine: <a href="https://www.wired.com/story/mayor-reno-betting-blockchain/" target="_blank">https://www.wired.com/story/mayor-reno-betting-blockchain/</a>
                </p>
                <p>
                    Discussion of the Reno DAO and the promise of crypto cities in Vitalik Buterin's personal blog: <a href="https://vitalik.ca/general/2021/10/31/cities.html" target="_blank">https://vitalik.ca/general/2021/10/31/cities.html</a>
                </p>
            </div>
    },
    {
        images: 'argonaut',
        title: 'Argonaut',
        category: 'Game',
        description:
            <div>
                <p>
                    <span style={{fontWeight: "bold"}}>Argonaut</span> is an open world survival-adventure game I developed with a team of five software
                    engineers. The game takes place in a post-apocalyptic world which has been invaded by a hostile alien civilization. The objective of
                    the game is to explore the world, encountering hordes of enemies and abandoned cities. Through exploring cities, the player can collect
                    increasingly powerful technology and weapons, enabling them defeat progressively stronger enemies.
                </p>
                <p>
                    The game environment is an infinite procedurally generated world based on a world seed. The terrain, biomes, cities, trees,
                    grass, and rocks are all entirely procedural. This allows a high level of replayability while never experiencing the game
                    the same way twice.
                </p>
                <p>
                    All procedural generation is accomplished with the use of multithreading to ensure seamless loading as the player moves through
                    the world. The game consistently runs at greater than 60fps on average hardware, with no noticeable load times.
                </p>
            </div>       
    },
    {
        images: 'marchingCubes',
        title: 'Marching Cubes Terrain Generation',
        category: 'Graphics',
        description:
            <div>
                <p>
                    I developed this marching terrain generation program for the Computer Graphics course at the University of Nevada, Reno. The project uses
                    OpenGL for rendering, BulletPhysics for physics simulations, and Blender for 3D modeling and texturing.
                </p>
                <p>
                    3D simplex noise is used to create a procedural noise map based on a world seed. After the noise undergoes postprocessing to control the frequency
                    of hills and caves, a marching cubes meshing algorithm is used to create the world mesh. The terrain is also procedurally textured based on 
                    height to achieve the green grass and grey caves seen in the screenshot above. The placement of trees is also procedural and based on the
                    world seed, and is dependent on the height and steepness of the terrain.
                </p>
                <p>
                    The world is loaded in a series three dimensional chunks. The project makes use of multithreading for all procedural generation. As a result,
                    the player experiences zero loading time as they explore the procedural environment.
                </p>
                <p>
                    This project also includes a simple game developed on top of the terrain system. The objective of the game is to pilot a flying saucer around
                    the terrain and abduct the roaming cows. The player's score increases when cows are abducted, and decreases when the player is seen by enemies
                    which are also patrolling the terrain. The game ends when time runs out or when the player has been spotted by enemies too many times.
                </p>
            </div>
    },
    {
        images: 'pca',
        title: 'Principle Component Analysis for Image Compression',
        category: 'Machine Learning',
        description: 
            <p>
            </p>        
    },
    {
        images: 'pinball',
        title: 'OpenGL Pinball',
        category: 'Graphics',
        description:
            <div>
                <p>
                    I developed this pinball game for the Computer Graphics course at the University of Nevada, Reno. The project is built
                    using the OpenGL render pipline. Additionally, the open source physics engine BulletEngine is intergrated with OpenGL
                    to create a realistic physics simulation including collisions, bounces, and friction.
                </p>
                <p>
                    The pinball board was modelled and textured entirely in Blender without the use of third party assets. In addition to the
                    model of the board, custom collision meshes were created in Blender to prevent clipping and improve physics performance.
                </p>
                <p>
                    Dynamic lighting is also used in this project. A spotlight on the ball can be seen in the above screenshot. This light follows
                    all movement of the ball to assist with gameplay. There are also colored lights which flash each time the ball collides with
                    the various bumpers on the board.
                </p>
                <p>
                    Background music and a variety of sound effects are also included using an open source sound library.
                </p>
            </div>   
    },
    {
        images: 'solarSystem',
        title: 'OpenGL Solar System Simulation',
        category: 'Graphics',
        description: 
            <p>
            </p>        
    },
    {
        images: 'neuralNetwork',
        title: 'Neural Network for Data Classification',
        category: 'Machine Learning',
        description: 
            <p>
            </p>        
    },
    {
        images: 'stacs',
        title: 'S.T.A.C.S.',
        category: 'Research',
        description: 
            <p>
                Project site: <a href="https://ecsl.cse.unr.edu/projects/bridge_inspection/index.html" target="_blank">https://ecsl.cse.unr.edu/projects/bridge_inspection/index.html</a>
            </p>        
    },
    {
        images: 'consensus',
        title: 'Sensor Network Consensus Algorithm',
        category: 'Robotics',
        description: 
            <p>
            </p>        
    },
    {
        images: 'kalman',
        title: 'Kalman Filter',
        category: 'Robotics',
        description: 
            <p>
            </p>        
    },
    {
        images: 'pole',
        title: 'Pole Balancing Neural Network',
        category: 'Machine Learning',
        description: 
            <p>
            </p>        
    },
    {
        images: 'spam',
        title: 'Email Spam Filter',
        category: 'Machine Learning',
        description: 
            <p>
            </p>        
    },
    {
        images: 'invaderarena',
        title: 'Invader Arena',
        category: 'Game',
        description:
            <div>
                <p>
                    <span style={{fontWeight: "bold"}}>Invader Arena</span> is a Virtual Reality video game I developed with the Unity3D game engine.
                    The game consists of defending against waves of increasingly aggressive alien space ships armed with laser guns. The player is
                    armed only with a lazer sword, and must deflect incoming laser blasts back at enemy ships. Points are scored if the deflected lazer
                    collides with and destroys a ship. The alien ships fire more frequently over time, and the game ends when the player's health
                    reaches zero.
                </p>
                <p>
                    Tools I used for this project include C#, Unity3D, and blender.
                </p>
                <p>
                    You can find a video demo of Invader Arena <a href="https://drive.google.com/file/d/1-Pr-7f7hih5o9fLyTfsKrqVI7RVycUKb/view?usp=sharing" target="_blank">here</a>.
                </p>
            </div>      
    },
    {
        images: 'netdefense',
        title: 'Net-Defense',
        category: 'Research',
        description:
            <div>
                <p>
                    <span style={{fontWeight: "bold"}}>Net-Defense</span> is a tower defense strategy game designed to teach players the core concepts of
                    cybersecurity. Gameplay consists of defending against waves of enemy 'packets' as they attempt to reach your 'servers' at the end of
                    their path. To fight back, the player can place routers which filter packets. The objective is to filter the disguised enemy packets
                    while ensuring all friendly packets still reach their destination.
                </p>
                <p>
                    I developed Net-Defense for the <a href="https://ecsl.cse.unr.edu/" target="_blank">Evolutionary Computing Systems
                    Laboratory (ECSL)</a> at the University of Nevada, Reno and presented it to teachers in the Washoe County School District to gather
                    feedback and advice.
                </p>
            </div>
    },
    {
        images: 'frogbotics',
        title: 'Frogbotics',
        category: 'Research',
        description: 
            <div>
                <p>
                    <span style={{fontWeight: "bold"}}>Frogbotics</span> is a an educational video game intended to teach basic mathematic operation to
                    school age children. Gameplay consists of students competing to be the first to complete addition, subtraction, multiplication, or
                    division operations. There is a provided catalog of problems, or the teacher can create custom problems based on their needs.
                </p>
                <p>
                    I developed Frogbotics for the <a href="https://ecsl.cse.unr.edu/" target="_blank">Evolutionary Computing Systems
                    Laboratory (ECSL)</a> at the University of Nevada, Reno and presented it to teachers in the Washoe County School District to gather
                    feedback and advice.
                </p>
                <p>
                    You can play the latest version of Frogbotics <a href="https://www.cse.unr.edu/~jonathanp/frogbotics/PlayGame/" target="_blank">here</a>.
                </p>
                <p>
                    
                </p>
            </div>      
    },
    {
        images: 'snake',
        title: 'Snake',
        category: 'Game',
        description: 
            <div>
                <p>
                    <span style={{fontWeight: "bold"}}>Snake</span> was my first programming project. I developed it using C# and the Unity3D game engine
                    and used Blender to create assets.
                </p>
                <p>
                    You can play my version of Snake <a href="https://www.cse.unr.edu/~jonathanp/snake/" target="_blank">here</a>.
                </p>
            </div>
    },
]

export default BlogContent;