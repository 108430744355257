import React, { useState } from "react";

const ContactThree = ({ env }) => {
    const [rnName, setRnName] = useState("")
    const [rnEmail, setRnEmail] = useState("")
    const [rnSubject, setRnSubject] = useState("")
    const [rnMessage, setRnMessage] = useState("")

    const handleSubmit = event => {
        event.preventDefault();

        sendFeedback({
            name: rnName,
            email: rnEmail,
            subject: rnSubject,
            message: rnMessage,
        })

        setRnName("")
        setRnEmail("")
        setRnSubject("")
        setRnMessage("")
    }

    const sendFeedback = ({
        name,
        email,
        subject,
        message,
    }) => {
        window.emailjs.send(
            "mailgun_service",
            "portfolio_template",
            {
                name,
                email,
                subject,
                message,
            },
            "RALJ4mnJJi-8mhV4Z",
        )
        .then(res => {
            if(res.status === 200) {
                alert("Message Sent Successfully")
            }
        })
        .catch(err => {
            alert("Failed to send: ", err)
            console.log("err: ", err)
        })
    }

    return(
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-8 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">Contact</h2>
                            <p className="description">Please contact me via email: 
                                <a href="jonathan.peters99@gmail.com"> jonathan.peters99@gmail.com</a> </p>
                        </div>
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={rnName}
                                        onChange={(e)=>{setRnName( e.target.value)}}
                                        placeholder="Your Name *"
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={rnEmail}
                                        onChange={(e)=>{setRnEmail(e.target.value)}}
                                        placeholder="Your Email *"
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        value={rnSubject}
                                        onChange={(e)=>{setRnSubject(e.target.value)}}
                                        placeholder="Subject"
                                    />
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        name="message"
                                        id="item04"
                                        value={rnMessage}
                                        onChange={(e)=>{setRnMessage(e.target.value)}}
                                        placeholder="Message"
                                    />
                                </label>
                                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactThree;