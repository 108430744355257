import React, { useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Modal from "react-modal";
import Helmet from "./component/Helmet";
import TextLoop from "react-text-loop";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Tab from "./component/Tab";
import Contact from "./component/Contact";
import BlogContent from "./component/BlogContent";
import {FaTwitter, FaLinkedinIn, FaGithub} from "react-icons/fa";

Modal.setAppElement("#root"); 

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const SocialShare = [
    {Social: <FaGithub /> , link: 'https://github.com/Jonathan-B-Peters/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/jonathan-b-peters/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/JonBPeters/'},
]

const PortfolioLanding = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [current, setCurrent] = useState(0);
  
    const toggleModal = (ind) => {
        setCurrent(ind);
        setIsOpen(!isOpen);
    }
    
    let title = 'About Me';
    const PostList = BlogContent.slice();
    return (
        <div className="active-dark">
            <Helmet pageTitle="Jonathan Peters" />

            <Header homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            <h1 className="title">Hi, I’m Jonathan Peters <br/>
                                            <TextLoop noWrap={false} interval={1500}>
                                                <span> Smart Contract Engineer</span>
                                                <span> Firmware Engineer</span>
                                                <span> Undergraduate Researcher</span>
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2>based in Reno, Nevada</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/Jonathan.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <h3 style={{marginBottom: "3%"}}>Jonathan Peters</h3>
                                                </li>
                                                <li>
                                                    <h5>Smart Contract Engineer</h5>
                                                </li>
                                                <li>
                                                    <h5>Reno, Nevada</h5>
                                                </li>
                                                {/* <li>
                                                    <a href="jonathan.peters99@gmail.com">Email: <span className="blue">jonathan.peters99@gmail.com</span></a>
                                                </li>
                                                <li>
                                                    <a href="https://github.com/Jonathan-B-Peters">Github: <span className="blue">https://github.com/Jonathan-B-Peters</span></a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/jonathan-b-peters">LinkedIn: <span>www.linkedin.com/in/jonathan-b-peters</span></a>
                                                </li> */}
                                            </ul>
                                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                                ))}
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="row mt--30">
                                            <Tab tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Portfolio</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a onClick={() => toggleModal(i)}>
                                                <img className="w-100" src={`/assets/images/portfolio/${value.images}.png`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" onClick={() => toggleModal(i)}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <Modal
                                isOpen={isOpen}
                                onRequestClose={() => toggleModal(current)}
                                contentLabel="My dialog"
                                className="mymodal"
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                            >
                                <div className="tokyo_tm_modalbox_news">
                                <button className="close-modal" onClick={() => toggleModal(current)}>
                                    <img src="assets/images/cancel.svg" alt="close icon" />
                                </button>
                                {/* END CLOSE ICON */}
                                <div className="box_inner">
                                    <div className="description_wrap scrollable">
                                    <div className="image">
                                        <div
                                        className="main"
                                        style={{
                                            backgroundImage: "url(assets/images/portfolio/popup_" + PostList[current].images + ".jpg)",
                                        }}
                                        ></div>
                                    </div>
                                    <div className="details">
                                        <div className="extra">
                                        </div>
                                        <h3 className="title">
                                        { PostList[current].title }
                                        </h3>
                                    </div>
                                    <div className="main_content">
                                        <div className="descriptions">
                                            {PostList[current].description}
                                            {/* <p>
                                                { PostList[current].description }
                                            </p> */}
                                        </div>
                                        {/* {
                                        texts[current].button.map((type, ind) => (
                                        <div key={"button_div" + ind} className="tokyo_tm_button">
                                        <a className="ib-button" href={type.url}>
                                            {type.name}
                                        </a>
                                        </div>
                                        ))} */}
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </Modal>
                        </div>    
                    </div>    
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <Contact />
                </div>
            </div>
            {/* End COntact Area */}

            <Footer />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;